<template>
  <div style="background-color: #ebedf0">
    <MyHeader :title="$t('lang.menu_repairdetails')" :ShowBack="true" BackUrl="/repair/index" :RightIcon="rightIcon"
      @rightclick="onRightClick" />
    <div class="repair-panel">
      <div>{{ $t("lang.time") }}：{{ mData.reportTime }}</div>
      <div>{{ $t("lang.router") }}：{{ mData.routeName }}</div>
      <div>
        {{ $t("lang.event") }}：{{ mData.placeName }}-{{ mData.eventName }}
      </div>
      <div>{{ $t("lang.inspector") }}：{{ mData.inspectorName }}</div>
      <div>{{ $t("lang.eventvalue") }}：{{ mData.eventValue }}</div>
    </div>

    <div class="repair-panel">
      <div class="repair-radio" v-show="mImage.length > 0 && mImage[0] != ''" v-for="(m, index) in mImage" :key="m">
        <van-image width="62" height="62" :src="m" @click="onShowImg(index)" />
      </div>
      <div class="repair-radio" v-show="mData.reportRecordUrl != ''">
        <van-icon name="music" size="40" style="margin-top: 10px" @click="onRecord()" />
      </div>
      <div class="repair-radio" v-show="mData.reportVideoUrl != ''">
        <van-icon name="play-circle" size="40" style="margin-top: 10px" @click="onVideo()" />
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="repair-panel">
      <div>{{ $t("lang.level") }}：{{ mData.repairLevel }}</div>
    </div>

    <div class="repair-panel">
      <van-steps direction="vertical" :active="3 - mRepairProgress">
        <van-step v-show="mRepairProgress >= 3 && mData.finishTime != ''">
          <div>
            【{{ $t("lang.completed") }}】&nbsp;&nbsp;{{ mData.finishTime }}&nbsp;&nbsp;{{ mData.repairUser }}
          </div>
          <p>{{ mData.repairComment }}</p>
          <div class="repair-radio" v-for="(m, index) in uploadFileShowList" :key="m">
            <van-image width="62" height="62" :src="m" @click="onShowFinishImg(index)" />
          </div>
          <div style="clear:both;"></div>
        </van-step>
        <van-step v-show="mRepairProgress >= 2 && mData.inProcessTime != ''">
          <div>
            【{{ $t("lang.processing") }}】&nbsp;&nbsp;{{ mData.inProcessTime }}&nbsp;&nbsp;{{ mData.repairUser }}
          </div>
          <p>{{ mData.inProcessComment }}</p>
        </van-step>
        <van-step v-show="mRepairProgress >= 1 && mData.reviewTime != ''">
          <div>
            【{{ $t("lang.audited") }}】&nbsp;&nbsp;{{ mData.reviewTime }}&nbsp;&nbsp;{{ mData.reviewUser }}
          </div>
          <p>{{ mData.reviewComment }}</p>
        </van-step>
        <van-step v-show="mRepairProgress >= 0 && mData.reportTime != ''">
          <div>
            【{{ $t("lang.applied") }}】&nbsp;&nbsp;{{ mData.reportTime }}&nbsp;&nbsp;{{ mData.inspectorName }}
          </div>
          <p>{{ mData.reportComment }}</p>
        </van-step>
      </van-steps>
    </div>

    <van-dialog v-model="showAudit" :title="mTitle" :before-close="onBeforeClose" show-cancel-button
      @confirm="onConfirm()">
      <van-field name="checkbox" :label="mLabel" label-width="50px">
        <template #input>
          <van-checkbox v-model="mState" shape="square" />
        </template>
      </van-field>
      <van-field rows="4" autosize :label="$t('lang.descriptions')" type="textarea"
        :placeholder="$t('lang.descriptions_input')" v-model="mRemark" label-width="50px" />
      <div style="padding:20px;" v-show="mRepairProgress == 2">
        <div style="font-size:14px;line-height: 24px;color:#646566;">{{ $t("lang.uploader") }}：</div>
        <van-uploader v-model="fileList" :max-count="2" :before-read="onBeforeRead" :after-read="onUpload" @delete="onDeleImg" />
      </div>
    </van-dialog>

    <van-dialog v-model="showVideo" show-cancel-button :show-confirm-button="false" :cancel-button-text="$t('lang.close')"
      @close="onClose()">
      <video-player class="video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions"
        :playsinline="true" customEventName="customstatechangedeventname">
      </video-player>
    </van-dialog>
  </div>
</template>
<style scoped>
.repair-panel {
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #646566;
  margin-top: 5px;
  background-color: #fff;
}

.van-image {
  margin-right: 5px;
}

.repair-radio {
  height: 62px;
  width: 62px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background-color: #eee;
  float: left;
  text-align: center;
}

.van-uploader .van-uploader__preview-delete {
  width: 20px;
  height: 20px;
}

.van-uploader .van-uploader__preview-delete-ico {
  font-size: 20px;
}
</style>
<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { ImagePreview } from "vant";
import Compressor from 'compressorjs';
import MyHeader from "@/components/Header.vue";
import {
  GetRepairDetail,
  GetServerUrl,
  UpdateRepairState,
  UploadImages
} from "@/api/index.js";

export default {
  components: { MyHeader, videoPlayer },
  data() {
    return {
      mData: {},
      mImage: [],
      mImage1: [],
      mRepairProgress: 0,

      detailid: 0,
      ServerUrl: "",

      rightIcon: "",
      showAudit: false,
      isReview: false,
      isRepair: false,
      isAbnormalHandle: false,

      showVideo: false,
      playerOptions: {
        muted: false,
        language: "zh-CN",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

      flagClose: false,
      mState: true,
      mTitle: "",
      mLabel: "",
      mRemark: "",

      fileList: [],             //已上传的完整路径
      uploadFileList: [],       //上传成功之后返回的数据,用于提交
      uploadFileShowList: [],   //上传成功之后返回的数据,用于显示缩略图
      uploadFileShowList1: []   //上传成功之后返回的数据,用于显示原图
    };
  },

  mounted() {
    //wid=o75Uh6FPFW53LN8NM886T612C78Q&act=accept

    let mPer = this.$store.getters.getPermission;
    this.isReview = mPer.isReview;
    this.isRepair = mPer.isRepair;
    this.rightIcon = this.isReview || this.isRepair ? "sign" : "";

    this.ServerUrl = GetServerUrl();
    this.detailid = this.$route.query.id;
    this.Query();
  },

  methods: {
    onRightClick() {
      //console.log(this.mRepairProgress);
      //console.log(this.isReview);
      //console.log(this.isRepair);
      //报修
      if (this.mRepairProgress == 0 && this.isReview) {
        this.mTitle = this.$t("lang.repairaudit");
        this.mLabel = this.$t("lang.agree");
        this.showAudit = true;
      } else if (
        this.mRepairProgress == 1 &&
        (this.isRepair || this.isAbnormalHandle)
      ) {
        this.mTitle = this.$t("lang.repairassign");
        this.mLabel = this.$t("lang.accept");
        this.showAudit = true;
      } else if (
        this.mRepairProgress == 2 &&
        (this.isRepair || this.isAbnormalHandle)
      ) {
        this.mTitle = this.$t("lang.repaircompleted");
        this.mLabel = this.$t("lang.complete");
        this.showAudit = true;
      } else {
        this.$Toast(this.$t("lang.permissiondenied"));
      }
    },

    async onBeforeClose(action, done) {
      if (action === "confirm" && !this.flagClose) {
        return done(false);
      } else {
        return done();
      }
    },

    onConfirm() {
      this.flagClose = true;
      if (!this.mState) {
        if (this.mRepairProgress != 0) {
          this.$Toast(
            this.$t("lang.additionalremarks_hint", { label: this.mLabel })
          );
          this.flagClose = false;
        } else if (this.mRemark == "") {
          this.$Toast(this.$t("lang.inputreason"));
          this.flagClose = false;
        }
      }
      if (!this.flagClose) return;

      let imageUrl = this.uploadFileList.join('|');
      UpdateRepairState(
        {
          token: this.$store.getters.getToken,
          id: this.detailid,
          comment: this.mRemark,
          userName: this.$store.getters.getUser.userName,
          imageUrl: imageUrl
        },
        !this.mState && this.mRepairProgress == 0 ? 2 : this.mRepairProgress,
        (ret) => {
          if (ret && ret.code == 0) {
            this.mState = true;
            this.mRemark = "";
            this.$store.commit("setReload", 1);
            this.$Toast(this.$t("lang.statechanges_success") + ret.data);
            this.Query();
          } else {
            this.$Toast(!ret ? this.$t("lang.statechanges_fail") : ret.message);
          }
        }
      );
    },

    onShowImg(i) {
      ImagePreview({
        images: this.mImage1,
        startPosition: i,
      });
    },

    onRecord() {
      this.playerOptions.sources[0].src =
        this.ServerUrl + this.mData.reportRecordUrl;
      this.showVideo = true;
    },

    onVideo() {
      this.playerOptions.sources[0].src =
        this.ServerUrl + this.mData.reportVideoUrl;
      this.showVideo = true;
    },

    onClose() {
      this.$refs.videoPlayer.player.pause();
    },

    Query() {
      GetRepairDetail(
        {
          token: this.$store.getters.getToken,
          id: this.detailid,
        },
        (ret) => {
          if (ret && ret.code == 0) {
            this.mData = ret.data;
            this.mRepairProgress = this.mData.state;
            let imgurl = this.mData.reportImageUrl.replace(RegExp("/app/images", "g"), this.ServerUrl + "app/images");
            this.mImage = imgurl.split("|");
            this.mImage1 = imgurl.replace(RegExp("preview/", "g"), "").split("|");

            let finishImgurl = this.mData.finishImageUrl.replace(RegExp("/app/images", "g"), this.ServerUrl + "app/images");
            if (finishImgurl != '') {
              this.uploadFileShowList = finishImgurl.split("|");
              this.uploadFileShowList1 = finishImgurl.replace(RegExp("preview/", "g"), "").split("|");
            }
          } else {
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onBeforeRead(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,                   //压缩质量, 0-1         
          convertTypes: ['image/jpeg'],   //转换的类型，默认为 image/png         
          convertSize: 3 * 1024 * 1024,   //需要压缩的起始大小，默认5M, 5 * 1000 * 1000    
          maxWidth: 1080,                 //压缩后的最大宽度
          maxHeight: 1920,                //压缩后的最大高度     
          success(result) {               //压缩成功后处理
            resolve(result);
          },
          error(err) {
            //console.log(err.message);
            reject()
          },
        });
      });
    },
    onUpload(file) {
      //console.log(file.file);
      file.status = 'uploading'
      file.message = '上传中...'
      let data = {
        file: file.file,
        token: this.$store.getters.getToken,
        routerid: 1,
        typeName: 'repairFinishImg'
      };
      UploadImages(data, (ret) => {
        file.status = ''
        file.message = ''
        if (ret.code == 0) {
          this.uploadFileList.push(ret.data.url);
        } else
          this.$Toast(ret.message);
      })
    },
    onDeleImg(file, detail) {
      this.uploadFileList.splice(detail.index, 1);
    },
    onShowFinishImg(i) {
      ImagePreview({ images: this.uploadFileShowList1, startPosition: i, });
    },

  },
};
</script>
